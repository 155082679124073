import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
// Layout component
import Layout from '../components/layouts/Layout';
import HorizontalMenu from '../components/HorizontalMenu';
import './privacy-policy.css';

const IndexPage = () => {
    const { t } = useTranslation('privacyPolicyMissionHerault');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_POLICY')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_POLICY')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{__html: t('TITLE')}}></h1>
                    <HorizontalMenu
                        list={[
                            {
                                url: '/privacy-policy/',
                                category: t('PRIVACY_POLICY')
                            },
                            {
                                url: '/terms-of-use/',
                                category: t('TERM_OF_USE')
                            },
                            {
                                url: '/terms-of-use-pro/',
                                category: t('TERM_OF_USE_PRO')
                            },
                            {
                                url: '/privacy-policy-rueil/',
                                category: t('PRIVACY_POLICY_RUEIL')
                            },
                            {
                                url: '/privacy-policy-mission-herault/',
                                isActive: true,
                                category: t('PRIVACY_POLICY_MISSION_HERAULT')
                            },
                            {
                                url: '/privacy-policy-paris-region-aventures/',
                                category: t('PRIVACY_POLICY_PARIS_REGION_AVENTURES')
                            },
                            {
                                url: '/privacy-policy-colmar-aventure/',
                                category: t('PRIVACY_POLICY_COLMAR_AVENTURE')
                            },
                            {
                                url: '/privacy-policy-mont-saint-michel-aventures/',
                                category: t('PRIVACY_POLICY_MONT_SAINT_MICHEL_AVENTURE')
                            }
                        ]}
                    />
                    <h2>{t('TITLE_PERSONAL_DATA')}</h2>

                    <p>{t('TITLE_POSTING_DATE')}</p>

                    <p>{t('TITLE_WELCOME')}</p>

                    <p>{t('TITLE_WEMAP_SAS')}</p>

                    <p>{t('TITLE_WEMAP_PROTECTION_DATA')}</p>

                    <p>{t('TITLE_GATHERING_OF_DATA')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GENERAL_TERMS')}}></p>

                    <p>{t('TITLE_MAY_INCLUDE')}</p>

                    <p>{t('TITLE_OBJECTIVE')}</p>

                    <p>{t('TITLE_RIGHTS')}</p>

                    <p>{t('TITLE_MISE_A_JOUR')}</p>

                    <p>{t('TITLE_RELATIVE_POLICY')}</p>

                    <p>{t('TITLE_APP')}</p>

                    <h2>{t('TITLE_DATA_CONTROLLER')}</h2>

                    <p>{t('TITLE_FRENCH_DATA_PROTECTION')}</p>

                    <p>{t('TITLE_INFOS_REGISTERED')}</p>

                    <h2>{t('TITLE_GATHER_PERSONAL_DATA')}</h2>

                    <p>{t('TITLE_BROWSE_OUR_SITE')}</p>

                    <p>{t('TITLE_DATA_COLLECTION')}</p>

                    <h3>{t('TITLE_USE_APP')}</h3>

                    <p>{t('TITLE_IDENTIFY')}</p>

                    <p>{t('TITLE_USE_COLLECT')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_GATHERED_INFOS')}}></p>

                    <h2>{t('TITLE_3_DATA')}</h2>

                    <p>{t('DESCRIPTION_3_PERSONAL_DATA')}</p>

                    <p>{t('TITLE_PERSONAL_DATA_USED')}</p>

                    <p>{t('TITLE_CROSS_REFERENCED')}</p>

                    <h2>{t('TITLE_4_SHARE_PERSONAL_DATA')}</h2>

                    <p>{t('TITLE_THIRD_PARTIES')}</p>

                    <p dangerouslySetInnerHTML={{__html: t('TITLE_FIRST_OBJECTIVE')}}></p>

                    <p>{t('TITLE_TEMOINS')}</p>

                    <p>{t('TITLE_DYSFONCTION')}</p>

                    <p>{t('TITLE_ALWAYS_PUBLIC')}</p>

                    <p>{t('TITLE_THIRD_PARTY_SOCIAL')}</p>

                    <p>{t('TITLE_IMPORTANT_FOR_APP')}</p>

                    <p>{t('TITLE_EXPLOTATION_APP')}</p>

                    <h2>{t('TITLE_5_DATA')}</h2>

                    <p>{t('TITLE_LOCATION_SERVICE')}</p>

                    <p>{t('TITLE_DIFFERENT_TECH')}</p>

                    <p>{t('TITLE_USE_GEO')}</p>

                    <p>{t('TITLE_NOT_CONSERVED')}</p>

                    <p>{t('TITLE_USE_OF_APP')}</p>

                    <h2>{t('TITLE_6_DATA')}</h2>

                    <p>{t('TITLE_LEGAL')}</p>

                    <p>{t('TITLE_INFORME')}</p>

                    <h3>{t('TITLE_CATEGORIES_AUTHORIZED')}</h3>

                    <p>{t('TITLE_PRIVATE_USE')}</p>

                    <p>{t('TITLE_STATISTIQUE')}</p>

                    <h3>{t('TITLE_DURABILITY')}</h3>

                    <p>{t('TITLE_THIRD_YEARS')}</p>

                    <p>{t('TITLE_MONTHS')}</p>

                    <h2>{t('TITLE_7_DATA')}</h2>

                    <p>{t('TITLE_COMMUNICATE')}</p>

                    <p>{t('TITLE_YOUR_INFO')}</p>

                    <p>{t('TITLE_HYPOTHESIS')}</p>

                    <p>{t('TITLE_CONSTRAINT')}</p>

                    <h2>{t('TITLE_8_DATA')}</h2>

                    <p>{t('TITLE_GOOGLE')}</p>

                    <p>{t('TITLE_IDENTIFICATION')}</p>

                    <p>{t('TITLE_STOCK')}</p>

                    <p>{t('TITLE_TRANSFERS')}</p>

                    <p>{t('TITLE_KNOW_MORE')}</p>

                    <h2>{t('TITLE_9_DATA')}</h2>

                    <p>{t('TITLE_TAKES_MEASURE')}</p>

                    <h2>{t('TITLE_10_DATA')}</h2>

                    <p>{t('TITLE_ASK')}</p>

                    <p>{t('TITLE_PROBLEMS')}</p>

                    <h2>{t('TITLE_11_DATA')}</h2>

                    <p>{t('TITLE_ACCESS_RIGHT')}</p>

                    <h2>{t('TITLE_12_DATA')}</h2>

                    <p>{t('TITLE_MOBILE_DEVICE')}</p>

                    <h2>{t('TITLE_13_DATA')}</h2>

                    <p>{t('TITLE_RESERVE_RIGHT')}</p>

                    <h2>{t('TITLE_14_DATA')}</h2>

                    <p style={{ marginBottom: '13em' }}
                        dangerouslySetInnerHTML={{__html: t('TITLE_CONTACT_US_AT')}}></p>

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;
